import { AllRoutes } from "./Routes/Routes";
// import {Routes,Route,Link} from 'react-router-dom'
function App() {
  return (
    <div className='App'>    

    <AllRoutes />

    </div>
  );
}

export default App;